import styled from "styled-components";

export const ServicesWrapper = styled.div`
width: 100%;
margin-top: 10rem;
background-color: #121212;
	@media (min-width: 0px) and (max-width: 999px) {
		margin-top: 0;
	}
`
export const ServicesContainer = styled.div`
	width: 112rem;
	margin: 0 auto;
	padding: 10rem 0 ;
	@media (min-width: 0px) and (max-width: 999px) {
		width: 31.87rem ;
		padding: 4.5rem 0 ;
	}
	h1{
		font-size: 4.5rem;
		font-weight: 600;
		line-height: 5.4rem;
		margin: 0 0 6.6rem 0;
		color: #FFFFFF;
	@media (min-width: 0px) and (max-width: 999px) {
		font-size: 2.97rem;
		line-height: 3.56rem;
	}
		
	}
	ul{
		width: 100%;
		li{
			display: flex;
			align-items: center;
			justify-content: space-between;
	@media (min-width: 0px) and (max-width: 999px) {
		flex-direction: column;
		align-items: start;
	}
			.service{
				display: flex;
				align-items: center;
				font-size: 1.6rem;
				font-weight: 600;
				line-height: 2.3rem;
				color: #F0F2F4;
				svg{
					margin-right: 1.3rem;
					width: 2.5rem;
				}
			}
			.serviceDescription{
				font-size: 1.5rem;
				font-weight: 400;
				line-height: 2rem;
				color: #C6CAD2;
				width: 45%;
	@media (min-width: 0px) and (max-width: 999px) {
		width: 100%;
		padding-left: 3.8rem;
		margin-top: 0.9rem;
				font-size: 1.45rem;

	}
			}
		}
	}
`

export const Line = styled.div`
	width: 100%;
	height: 1px;
	background-color: #3D3F44;
	margin: 4rem 0;
`