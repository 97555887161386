import styled from "styled-components";

export const AboutUsWrapper = styled.div`
	width: 100%;
	h4{
		font-size: 1.6rem;
		font-weight: 600;
		line-height: 2.3rem;
		color: #161718;
		margin-bottom: 1.3rem;
		margin-top: 4rem;
	}

	p{
		font-size: 1.5rem;
		font-weight: 400;
		line-height: 2rem;
		color:#676B72;
@media (min-width: 0px) and (max-width: 999px) {
	font-size: 1.49rem;
}
	}
`
export const AboutUsContainer = styled.div`
width: 112rem;
margin: 0 auto;
padding-bottom: 10rem;
@media (min-width: 0px) and (max-width: 999px) {
width: 31.87rem ;
padding-bottom: 4.46rem;

}
`
export const AboutUsFirstSection = styled.div`
	padding-top: 10rem;
	display: flex;
	justify-content: space-between;
	align-items: start;
@media (min-width: 0px) and (max-width: 999px) {
	flex-direction: column;
	align-items: center;
	padding-top: 4.46rem;

}
	.aboutUsDescription{
	width: 45%;
@media (min-width: 0px) and (max-width: 999px) {
	width: 100%;
}
	h1{
		margin-bottom: 1.3rem;
@media (min-width: 0px) and (max-width: 999px) {
	font-size: 3.9rem;
	font-family: Inter;
font-size: 42px;
font-weight: 600;
line-height: 50.4px;
text-align: left;

}
	}
	}


`

export const ImplicationsContainer = styled.div`
margin-top: -12rem;
	display: flex;
	justify-content: space-between;
	align-items: end;
@media (min-width: 0px) and (max-width: 999px) {
	margin-top: 0;
	flex-direction: column;
	align-items: start;
}
	.ImplImg{
		width: 54.6rem;
@media (min-width: 0px) and (max-width: 999px) {
	width: 100%;
}
		img{
			width: 100%;
			height: 60.3rem;
			object-fit: cover;
@media (min-width: 0px) and (max-width: 999px) {
	object-fit: fill;
	height: auto;
}
		}
	}
	.implication{
		width: 54.6rem;
@media (min-width: 0px) and (max-width: 999px) {
	width: 100%;
}
	}

`

export const ImgContainer = styled.div`
	width: 120rem;
	margin: 5rem auto -15rem auto;

	img{
		height: 100%;
		width: 100%;
		object-fit: cover;

	}
	
	.mobileImg{
		display: none;
	}

	@media (min-width: 0px) and (max-width: 999px) {
		width: 31.87rem ;
	.fullImg{

		display: none;
	}
	.mobileImg{
		display: block;
		width: 100%;
	}
}
`
export const BlackContainer = styled.div`
	width: 100%;
	height: 30rem;
	background-color: #000000;
	@media (min-width: 0px) and (max-width: 999px) {
		margin-bottom: -15rem;
		z-index: -1;
	}
`
