import { OurClientsContainer, OurClientsWrapper } from "./clients.styles";
import map from '../../assets/img/map.png'
const OurClients = ()=>{
	return(
		<OurClientsWrapper>
			<OurClientsContainer>
				<div className="presentation">
					<h1>Our clients from all over the world</h1>
					<p>Having accomplished projects for over 25 clients globally, our reach extends to various regions, with a predominant client base in the USA, Europe, and the UK.</p>
				</div>

				<div className="map">
					<img src={map} alt="map" />
				</div>
			</OurClientsContainer>

		</OurClientsWrapper>
	)
} 

export default OurClients;