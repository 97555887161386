import { CurrentTime, HeaderContainer, HeaderWrapper } from "./header.styles";
import {ReactComponent as Logo} from '../../assets/logo/bricksLogo.svg';

import React, { useState, useEffect, Fragment } from 'react';
import { Outlet } from "react-router-dom";

const Header =()=>{
	 const [time, setTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 1000);
    return () => clearInterval(interval); 
  }, []);

  const options = {
    timeZone: 'Europe/Chisinau',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  };

  const formattedTime = new Intl.DateTimeFormat('en-US', options).format(time);
	return(
		<Fragment>
			<HeaderWrapper>
				<HeaderContainer>
					<Logo className="fullLogo"/>
					<CurrentTime>{formattedTime} / Moldova</CurrentTime>
				</HeaderContainer>
			</HeaderWrapper>
			<Outlet/>
		</Fragment>

	)
}

export default Header;