import styled from "styled-components";

export const ContactUsWrapper = styled.div`
width: 100%;

`
export const ContactUsContainer = styled.div`
width: 120rem;
margin: 0 auto;
padding-left: 4rem;
display: flex;
justify-content: space-between;
@media (min-width: 0px) and (max-width: 999px) {
		width: 34.8513rem ;
		flex-direction: column;
		padding-left: 0;
  }
.contactUs{
	padding: 10rem 0;
	@media (min-width: 0px) and (max-width: 999px) {
		padding: 2.97rem 0 2.97rem 1.48rem;
	}
	h1{
	font-size: 6.6rem;
	font-weight: 600;
	line-height: 8rem;
	color: #161718;
	@media (min-width: 0px) and (max-width: 999px) {
		font-size: 3.9rem;
		line-height: 4.68rem;
	}
	}
	ul{
		margin-top: 15.16rem;
	@media (min-width: 0px) and (max-width: 999px) {
		margin-top: 3.9rem;

	}
		li{
			margin-top: 2rem;
			.reference{
				margin-left: 0.83rem;
				font-size: 2rem;
				font-weight: 400;
				line-height: 2.83rem;
				color: #161718;
	@media (min-width: 0px) and (max-width: 999px) {
		font-size: 1.67rem;
		line-height: 2.23rem;
	}
			}
			svg{
				height: 2rem;
	@media (min-width: 0px) and (max-width: 999px) {
		height: 1.4rem;
	}
				path{
					fill:#161718;
				}
			}
		}
	}
}

.locationImg{
	width: 60rem;
	img{
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
@media (min-width: 0px) and (max-width: 999px) {
	width: 100%;
	height: 37rem;
	img{
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
}
`
