import styled from "styled-components";

export const ProjectContainer = styled.div`
width: 100%;
`

export const ProjectImg = styled.div`
width: 100%;
height: 38.3rem;
img{
	width: 100%;
	height: 100%;
	object-fit: cover;
}

	@media (min-width: 0px) and (max-width: 999px) {
		height: auto;
		img{
			width: 100%;
			object-fit: fill;
		}
	}

`
export const ProjectDescription = styled.div`
display: flex;
justify-content: space-between;
	padding-top: 2.6rem;
	align-items: end;
	@media (min-width: 0px) and (max-width: 999px) {
		flex-direction: column;
		align-items: start;
	}
	.description{
		width: 34%;
	@media (min-width: 0px) and (max-width: 999px) {
		width: 100%;
	}
	}
	h2{
		font-size: 2.6rem;
		font-weight: 400;
		line-height: 3.6rem;
		text-align: left;
		color: #161718;
	}
	p{
		margin-top: 1.3rem;
		font-size: 1.5rem;
		font-weight: 400;
		line-height: 2rem;
		text-align: left;
		color: #676B72;
	}

	.skills{
	@media (min-width: 0px) and (max-width: 999px) {
		margin-top: 1.48rem;
	}
		.label{
			font-size: 1.3rem;
			text-transform: uppercase;
			font-weight: 400;
			line-height: 1.83rem;
			color: #161718;
			padding: 0.5rem 1.3rem;
		}
		.skill1{
			background-color: #AED9E0;
			margin-right: 0.6rem;
		}
		.skill2{
			background-color: #DEAAFF;
			margin-right: 0.6rem;
		}
		.skill3{
			background-color: #FFCBB0;
		}
	}

`