import styled from "styled-components";

export const FooterWrapper = styled.div`
width: 100%;
background-color:#000000 ;
`
export const FooterContainer = styled.div`
width: 112rem;
margin: 0 auto;
padding-top: 5rem;
padding-bottom: 5.3rem;
.mobileLogo{
	display: none;
}
@media (min-width: 0px) and (max-width: 999px) {
		width: 31.87rem ;
		padding-top: 5.57rem;
		padding-bottom: 3.71rem;
		.fullLogo{
			display: none;
		}
		.mobileLogo{
			display: block;
			width: 100%;
		}
	}
`
export const CallOutContainer = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
@media (min-width: 0px) and (max-width: 999px) {
	flex-direction: column;
	align-items: start;
	gap: 2.97rem;
	}
h1{
	width: 66%;
	font-size: 6.6rem;
	font-weight: 600;
	line-height: 9.3rem;
	color: #F0F2F4;
	@media (min-width: 0px) and (max-width: 999px) {
		width: 100%;
		font-size: 3.9rem;
		line-height: 5.46rem;
	}
}
.conversationCallOut{
	width: 30rem;
	p{
		color: #C6CAD2;
		font-size: 1.5rem;
		font-weight: 400;
		line-height: 2rem;
		margin-bottom: 2.6rem;
	}
	@media (min-width: 0px) and (max-width: 999px) {
	width	: 100%;
	}
}

button{
	width: 100%;
	a{
		color: #121212;
		width: 100%;
		font-size: 1.5rem;
		line-height: 2rem;
		background-color: #FFFFFF;

		svg{
			margin-left:0.3rem;
			height: 1.2rem;
			transform: rotate(0deg);
			path{
				fill:#121212;
			}
		}
	}
	
}
`
export const FooterContacts = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
.phoneLine{
	display: none;
}
@media (min-width: 0px) and (max-width: 999px) {
	flex-direction: column;
	align-items: start;
.phoneLine{
	display: block;
}
.onlineContacts{
	width: 100%;
	flex-direction: row;
	justify-content: space-between;}
	}
ul{
	display: flex;
	gap: 2.6rem;
	@media (min-width: 0px) and (max-width: 999px) {
		flex-direction: column;
	}
}
a{
	color: #FFFFFF;
	display: flex;
	align-items: center;

	font-size: 1.3rem;
	font-weight: 400;
	line-height: 1.83rem;

}
svg{
	height: 1.5rem;
	width: 1.5rem;
	margin-right: 0.83rem;
}
`
export const FooterLogo = styled.div`
width: 100%;
svg{
	width: 100%;
}
`

export const Line = styled.div`
	width: 100%;
	height: 1px;
	background-color: #3D3F44;
	margin: 5.3rem 0;
	@media (min-width: 0px) and (max-width: 999px) {
		margin: 3.9rem 0;
	}
`
export const LastLine = styled.div`
	width: 100%;
	height: 1px;
	background-color:#676B72 ;
	margin: 5.3rem 0;
		@media (min-width: 0px) and (max-width: 999px) {
		margin: 3.9rem 0;
	}
`
export const FooterPolicy = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	@media (min-width: 0px) and (max-width: 999px) {
		flex-direction: column-reverse;
		align-items: start;
		gap: 2rem;
	}
	span, a{
		color: #FFFFFF;
		font-size: 1.3rem;
		font-weight: 400;
		line-height: 1.83rem;
	}
	.rights{
		span{
			
		}
	}
	.terms{
		display: flex;
		gap: 2.6rem;
	}
`