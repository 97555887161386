import { CarouselContainer } from "./carousel.styles";
import { useState, useEffect } from "react";
import {ReactComponent as LeftArrow} from '../../assets/svgs/left.svg';
import {ReactComponent as RightArrow} from '../../assets/svgs/right.svg';
const Carousel = ({ testimonials }) => {
  const [current, setCurrent] = useState(0);
  const [fade, setFade] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setFade(false), 0);
    return () => clearTimeout(timer);
  }, [current]);

  const handleNext = () => {
    setFade(true);
    setTimeout(() => {
      setCurrent((prev) => (prev + 1) % testimonials.length);
    }, 500);
  };

  const handlePrev = () => {
    setFade(true);
    setTimeout(() => {
      setCurrent((prev) => (prev - 1 + testimonials.length) % testimonials.length);
    }, 500);
  };

  return (
    <CarouselContainer>
      <div className="testimonial">
        <button className="arrow left-arrow" onClick={handlePrev}>
          <LeftArrow/>
        </button>
        <div className={`testimonial-content ${fade ? 'fade' : 'active'}`}>
          <img
            src={testimonials[current].image}
            alt={testimonials[current].name}
            className="testimonial-image"
          />
          <p className="testimonial-review">{testimonials[current].review}</p>
          <h3 className="testimonial-name">{testimonials[current].name}</h3>
          <div className="testimonial-rating">
            {'★'.repeat(testimonials[current].rating)}
            {'☆'.repeat(5 - testimonials[current].rating)}
          </div>
        </div>
        <button className="arrow right-arrow" onClick={handleNext}>
          <RightArrow/>
        </button>
      </div>
      <div className="dots">
        {testimonials.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === current ? 'active' : ''}`}
            onClick={() => {
              setFade(true);
              setTimeout(() => {
                setCurrent(index);
              }, 500);
            }}
          ></span>
        ))}
      </div>
    </CarouselContainer>
  );
};

export default Carousel;
