import { Line, ServicesContainer, ServicesWrapper } from "./services.styles";
import {ReactComponent as UxSvg} from '../../assets/svgs/ux.svg'
import {ReactComponent as FrontSvg} from '../../assets/svgs/front.svg'
import {ReactComponent as BackSvg} from '../../assets/svgs/back.svg'
import {ReactComponent as AiSvg} from '../../assets/svgs/ai.svg'
import {ReactComponent as TestingSvg} from '../../assets/svgs/testing.svg'

const Services =()=>{
	return(
		<ServicesWrapper>
			<ServicesContainer>
				<h1>Our Services</h1>

				<ul>
					<li>
						<div className="service"><UxSvg/> UX/ UI design</div>
						<div className="serviceDescription">In the discovery phase, we extensively research your project, employing various methods to understand your audience and competitors. This informs our design and development decisions, aligning with your business goals.</div>					
					</li>
					<Line></Line>
					<li>
						<div className="service"><FrontSvg/> Front - End developing</div>
						<div className="serviceDescription">Our skilled developers transform designs into functional versions on staging servers. Close collaboration ensures seamless integration of designs into development.</div>					
					</li>
					<Line></Line>

					<li>
						<div className="service"><BackSvg/> Back - End developing</div>
						<div className="serviceDescription">We meticulously test websites on various platforms and devices to ensure functionality and user-friendliness, aiming to boost customer conversion and retention.</div>					
					</li>
					<Line></Line>

					<li>
						<div className="service"><AiSvg/> Artificial inteligence</div>
						<div className="serviceDescription">With the increased popularity of the free software movement , a lot of the software being created, including A.I. systems, is available for public exploit. The next natural step is to merge these individual software components into coherent, intelligent systems of a broader nature.</div>					
					</li>
					<Line></Line>

					<li>
						<div className="service"><TestingSvg/> Testing</div>
						<div className="serviceDescription">Once you've thoroughly tested and approved the final website version on our staging server, we initiate the migration to your live server. We perform a final checklist to ensure smooth operation before launching. We offer content management training for full website utilization and provide ongoing support as needed.</div>					
					</li>
				</ul>
			</ServicesContainer>
		</ServicesWrapper>
	)
}

export default Services;