import { ButtonContainer, ButtonLink } from "./button.styles";
import {ReactComponent as Rarrow} from '../../assets/svgs/rarrow.svg'
const Button =(props)=>{
	return(
		<ButtonContainer id={props.id}>
			<div className="link_container">
				<ButtonLink to={props.link}>{props.text} <Rarrow/></ButtonLink>
			</div>
		</ButtonContainer>
	)
}

export default Button;