import Button from "../button/button.component";
import { CallOutContainer, FooterContacts, FooterContainer, FooterLogo, FooterPolicy, FooterWrapper, LastLine, Line } from "./footer.styles";
import {ReactComponent as Phone} from '../../assets/svgs/phone.svg'
import {ReactComponent as Sms} from '../../assets/svgs/mail.svg'
import {ReactComponent as Location} from '../../assets/svgs/location.svg'
import {ReactComponent as Logo} from '../../assets/logo/bricksLogo.svg'
import bricksMobileLogo from '../../assets/logo/bricksLogoMobile.png'
import { Fragment } from "react";
import { Outlet } from "react-router-dom";
const Footer =()=>{
	return(
		<Fragment>
		<Outlet/>
		<FooterWrapper>
			<FooterContainer className="footerContainer">
				<CallOutContainer>
					<h1>LET’S MAKE IT HAPPEN TOGETHER</h1>
					<div className="conversationCallOut">
						<p>We’re an award-winning independent creative agency based in London. We take brands further through strategy, creativity and digital innovation.</p>

						<Button id='ContactUs' text="START A CONVERSATION" link="ContactUs"/>
					</div>
				</CallOutContainer>

				<Line></Line>
				
				<FooterContacts>
					<ul className="directContacts">
						<li><a href="tel:+37369025925"><Phone/>+373 69 025 925</a></li>
						<li><a href="sms:+373234424322"><Sms/>+373 69 025 925</a></li>
						<li><a href="https://maps.app.goo.gl/YncSdVCu8nkvzVqL7"><Location/>Miron Costin 17/2 str. of.519, Chisinau, MD-2068, Republic of Moldova</a></li>
					</ul>
					<Line className="phoneLine"></Line>
					<ul className="onlineContacts">
						<li><a href="#">Instagram</a></li>
						<li><a href="#">Facebook</a></li>
						<li><a href="#">Linkedin</a></li>
					</ul>
				</FooterContacts>

				<Line></Line>

				<FooterLogo>
					<Logo className="fullLogo"/>
					<img src={bricksMobileLogo} alt="brickslogo" className="mobileLogo" />
				</FooterLogo>

				<LastLine></LastLine>
				<FooterPolicy>
					<div className="rights">
						<span>
							&#169; ALL RIGHTS RESERVED
						</span>
					</div>
					<div className="terms">
						<a href="#">
							<span>Terms and conditions</span>
						</a>
						<a href="#">
							<span>Privacy policy</span>
						</a>
					</div>
				</FooterPolicy>
			</FooterContainer>
		</FooterWrapper>
		</Fragment>
	)
}

export default Footer;