import styled from "styled-components";
import { Link } from "react-router-dom";
export const ButtonContainer = styled.button`
background: none;
`

export const ButtonLink = styled(Link)`
		width: 33.3rem;
		padding: 1rem 0;
		background-color:#121212 ;
		color: #FFFFFF;
		text-transform: uppercase;
		font-size: 1.3rem;
		font-weight: 600;
		line-height: 1.83rem;
		display: flex;
		align-items: center;
		justify-content: center;
	@media (min-width: 0px) and (max-width: 999px) {
		width: 31.87rem ;
	}

		svg{
			margin-left:0.3rem;
			height: 1.1rem;
			transform: rotate(-45deg);
			path{
				fill:white ;
			}
		}
`