import styled from "styled-components";

export const GiftModalWrapper = styled.div`
	width: 100vw;
	height: 100vh;
	z-index: 1000;
	position: fixed;
	top: 0;
	left: 0;
	background-color: #00000080;
	.closeBackground{
		width: 100%;
		height: 100%;
		z-index: 0;
	}
`

export const GiftModalContainer = styled.div`
z-index: 1;
	background-color: #FFFFFF;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
@media (min-width: 0px) and (max-width: 999px) {
	gap: 0;
	flex-direction: column-reverse;
	transform: none;
	top: auto;
	bottom: 0;
	left: 0;
	width: 100%;
}

	@media (min-width: 515px) and (max-width: 649px) {
		scale: 0.8;
	}
	@media (min-width: 650px) and (max-width: 999px) {
		scale: 0.6;
	}

	.giftDescriptionContainer{
		padding: 1.6rem 1.3rem;
		@media (min-width: 0px) and (max-width: 999px) {
			text-align: center;
			padding: 0rem 1.1rem 1.6rem 1.1rem;

		}
		h3{
			font-size: 2rem;
			font-weight: 600;
			line-height: 2.83rem;
			color: #121212;
		}
		.promotionNumber{
			font-size: 10.3rem;
			font-weight: 800;
			line-height: 10.3rem;
			color: #1E966E;
			text-shadow: 0.3rem 0.3rem 0.3rem  #001E0061;
		}

		form{
			margin-top: 4.3rem;
			input{
				width: 100%;
				border: none;
				outline: none;
				background-color: #F5F6F7;
				padding: 1rem;
				color: #848891;
				font-size: 1.3rem;
				font-weight: 400;
				line-height: 1.83rem;

			}
			.buttonContainer{
				margin-top:2rem;
				display: flex;
				gap: 0.6rem;
		@media (min-width: 0px) and (max-width: 999px) {
			flex-direction: column-reverse;
		}
				button{
					cursor: pointer;
					width: 14.6rem;
					height: 3.83rem;
					border: 1px solid #D9DBDF;
					font-size: 1.3rem;
					font-weight: 600;
					line-height: 1.83rem;
					text-align: center;
					background-color: #FFFFFF;
					@media (min-width: 0px) and (max-width: 999px) {
						width: 100%;
					}
				}
				.submitButton{
					border: none;
					background-color: #121212;
					color: #FFFFFF;
				}
			}
		}
	}
	.giftImg{
		width: 27rem;
		img{
			width: 100%;
		}
@media (min-width: 0px) and (max-width: 999px) {
	width: 100%;
	background-color: #FFFFFF;
	text-align: center;
	img{
		width: 15.1rem;
	}
}
	}
`