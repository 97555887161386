import styled from "styled-components";

export const NavigationMobileWrapper = styled.div`
  position: fixed;
  bottom: 1.5%;
  left: 50%;
  transform: translateX(-50%);
  background: #000000e2;
  border-radius: 1rem;
  z-index: 100;
  padding: 0.5rem;
  width: ${(props) => (props.isOpen ? "33.45rem" : "29.73rem")};
  height: ${(props) => (props.isOpen ? "30.11rem" : "5.01rem")};
  transition: width 0.4s ease, height 0.4s ease;
  flex-direction: ${(props) => (props.isOpen ? "column" : "row")};
  justify-content: ${(props) => (props.isOpen ? "flex-start" : "space-between")};
  overflow: hidden;
  color: white;
  display: none;
@media (min-width: 0px) and (max-width: 999px) {
	display: block;
}
`;



export const NavigationMobileContainer = styled.div`
  display: ${(props) => (props.isOpen ? "flex" : "flex")};
  transition: opacity 0.4s ease-in-out;
  width: 100%;
  margin-top: ${(props) => (props.isOpen ? "1rem" : "0")};
  
  
  .mainMenu{
	height: 5.01rem;
  width: 29.73rem;  

		 display: flex;
		 justify-content: space-between;
		 align-items: center;
	position: absolute;
	bottom: 0%;
	left: 50%;
	transform: translateX(-50%);
  }
.buttonLink{
	margin-right: 0.74rem;
	a{
		border-radius: 1rem;
		width: 14.16rem;
		color: #121212;
		background-color: #FFFFFF;
		font-size: 1.3rem;
		font-weight: 600;
		line-height: 1.83rem;
		text-transform: none;
		padding: 0.8rem;
		svg{
			path{
				fill: #121212;
			}
		}
	}
}

.links{
	padding: 0 0.74rem;
	font-size: 1.48rem;
	font-weight: 400;
	line-height: 2.04rem;
	width: 100%;
	a{
		position: relative;
		padding-left: 4rem;
		color: #ffffff;
		width: 100%;
	}
.link{
	width: 100%;
	margin-top: 0.2rem;
	display: flex;
	align-items: center;
	gap: 0.37rem;
	padding: 1.11rem 1.48rem;
	border-radius: 1rem;
}
	svg{
		position: absolute;
		top: 0.2rem;
		left: 1rem;
		width: 1.85rem;
		path{
			fill: white;
		}
	}
}
`;
export const BurgerMenu = styled.div`
margin-left: 1.48rem;
display: flex;
font-size: 1.48rem;
line-height: 2.04rem;
gap: 0.37rem;
svg{
		width: 1.85rem;
	}
`





