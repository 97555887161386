import { AnimatedPoligonContainer } from "./animatedPoligon.styles";
import {ReactComponent as Skelet} from "../../assets/animatedPoligon/skelet.svg"
import {ReactComponent as Pol1} from "../../assets/animatedPoligon/pol1.svg"
import {ReactComponent as Pol2} from "../../assets/animatedPoligon/pol2.svg"
import {ReactComponent as Pol3} from "../../assets/animatedPoligon/pol3.svg"
import {ReactComponent as Pol4} from "../../assets/animatedPoligon/pol4.svg"
import {ReactComponent as Pol5} from "../../assets/animatedPoligon/pol5.svg"

const AnimatedPoligon = ()=>{
	return(
		<AnimatedPoligonContainer>
			<div className="skelet">
				<Skelet/>
			</div>
			<div className="pol1 polAb"><Pol1/></div>
			<div className="pol2 polAb"><Pol2/></div>
			<div className="pol3 polAb"><Pol3/></div>
			<div className="pol4 polAb"><Pol4/></div>
			<div className="pol5 polAb"><Pol5/></div>
		</AnimatedPoligonContainer>
	)
}

export default AnimatedPoligon;