import { useContext } from "react";
import Button from "../button/button.component";
import { WhyUsContainer, WhyUsDescription, WhyUsLine, WhyUsNumbers, WhyUsWrapper } from "./whyUs.styles";
import { ModalContext } from "../../contexts/modal.context";

const WhyUs = ()=>{
	const {isModalOpen, setIsModalOpen} = useContext(ModalContext);

	return(
		<WhyUsWrapper>
			<WhyUsContainer>
				<WhyUsDescription>
					<h1>Why Choose Us?</h1>
					<p>Our highly-skilled and trained team offers the best of both worlds - breadth and depth of expertise to deliver inclusive solutions on a global level. At our core, we are passionate professionals committed to helping others succeed. Our services are competitively priced and accessible across the board.</p>
					<div onClick={()=> setIsModalOpen(!isModalOpen)}>
						<Button id='startAProject' text="START A PROJECT"/>
					</div>
				</WhyUsDescription>

				<WhyUsLine></WhyUsLine>

				<WhyUsNumbers>
					<ul>
						<li>
							<div className="number">7+</div>
							<div className="description">Years of experience in IT</div>
						</li>
						<li>
							<div className="number">200+</div>
							<div className="description">Skilled and Trained Teams</div>
						</li>
						<li>
							<div className="number">100+</div>
							<div className="description">Cost-effective Services</div>
						</li>
						<li>
							<div className="number">$120M+</div>
							<div className="description">Our company turnover</div>
						</li>
					</ul>
				</WhyUsNumbers>
			</WhyUsContainer>
		</WhyUsWrapper>
	)
}

export default WhyUs;