import styled from "styled-components";

export const HeaderWrapper = styled.div`
	width: 100%;
	height: 6.6rem;
	background-color: #121212;

	@media (min-width: 0px) and (max-width: 999px) {
		height: 5.3rem;
	}
`
export const HeaderContainer = styled.div`
	width: 112rem;
	height: 100%;
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: space-between;
	.fullLogo{
		width: 11.6rem;
		
	}
	
	


	@media (min-width: 0px) and (max-width: 999px) {
		width: 31.87rem ;
	}
`

export const CurrentTime = styled.div`
	color: #676B72;
	font-weight: 400;
	font-size: 1.3rem;
`

