import styled from "styled-components";

export const ReviewsWrapper = styled.div`

`


export const ReviewsContainer = styled.div`
width: 112rem;
margin: 0 auto;
padding: 10rem 0;
	@media (min-width: 0px) and (max-width: 999px) {
		width: 31.87rem ;
		padding: 4.46rem 0 ;
	}
	h1{
		text-align: center;
		font-size: 6.6rem;
		font-weight: 600;
		line-height: 8rem;
		padding:0 0 3.5rem 0;
	@media (min-width: 0px) and (max-width: 999px) {
		font-size: 3.9rem;
		line-height: 4.68rem;
	}
	}
`