import Button from "../button/button.component";
import Project from "../project/project.component";
import { Line, ProjectsContainer, ProjectsHeader, ProjectsHeaderContainer, ProjectsHeaderToReview, ProjectsWrapper } from "./projects.styles";

import prj1 from '../../assets/img/prj1.jpg'
import prj2 from '../../assets/img/prj2.jpg'
import prj3 from '../../assets/img/prj3.jpg'

const Projects = ()=>{
	return(
		<ProjectsWrapper>
			<ProjectsContainer id="projectsId">
				<ProjectsHeaderContainer>
					<ProjectsHeader>
						WE DO GREAT WORK
					</ProjectsHeader>
					<ProjectsHeaderToReview>
						<p>We uncover insights to acheve timelessness and relevance in this rapidly changing world</p>
						<Button id='reviews' text="Reviews" link="Reviews"/>

					</ProjectsHeaderToReview>

				</ProjectsHeaderContainer>

				<Project 
					imgUrl={prj1} 
					descHeader="UX DESIGN"
					desc="We done a redesign of the product, applying robust UX design methodologies."
					skill1="SAAS"
					skill2="WEB APP"
					skill3="REDESIGN"
				/>

				<Line></Line>

				<Project 
					imgUrl={prj2} 
					descHeader="Full stack developing"
					desc="We done a redesign of the product, applying robust UX design methodologies."
					skill1="SAAS"
					skill2="WEB APP"
					skill3="REDESIGN"
				/>

				<Line></Line>

				<Project 
					imgUrl={prj3} 
					descHeader="Redesing + Front End develop"
					desc="We done a redesign of the product, applying robust UX design methodologies."
					skill1="SAAS"
					skill2="WEB APP"
					skill3="REDESIGN"
				/>
			</ProjectsContainer>
		</ProjectsWrapper>
	)
}

export default Projects;