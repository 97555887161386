import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ModalProvider } from './contexts/modal.context';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
	<BrowserRouter>
		<ModalProvider>
			<App />
		</ModalProvider>
	</BrowserRouter>
  </React.StrictMode>
);


