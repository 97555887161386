import styled, { keyframes } from "styled-components";

const Slide = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
`;

export const Characteristics = styled.div`
  position: relative;
  width: 120rem;
  margin: 0 auto;
  background-color: #121212;
  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  gap: 2.6rem;
  padding: 1.75rem 0;

  @media (min-width: 0px) and (max-width: 999px) {
		width: 34.8513rem ;
  }
`;

export const CharacteristicsSlide = styled.ul`
  display: flex;
  animation: ${Slide} 12s linear infinite; 
  gap: 2.6rem;

  li {
    display: flex;
    align-items: center;
    gap: 2.6rem;

    span {
      font-size: 1.5rem;
      line-height: 2rem;
      font-weight: 400;
    }
  }

  svg {
    height: 1.6rem;

    path {
      fill: #e11b22;
    }
  }
`;
