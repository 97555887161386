import styled from "styled-components";

export const WhyUsWrapper = styled.div`
	width: 100%;

`
export const WhyUsContainer = styled.div`
	width: 112rem;
	margin: 0 auto;
	padding-top: 10rem;
	display: flex;
	align-items: start;
	justify-content: space-between;
	@media (min-width: 0px) and (max-width: 999px) {
		width: 31.87rem ;
		padding-top: 4.46rem;
		flex-direction: column;
	}

`

export const WhyUsDescription = styled.div`
width:53.3rem;
	@media (min-width: 0px) and (max-width: 999px) {
		width: 100%;
	}
h1{
	font-size: 4.5rem;
	margin-bottom: 2.916rem;
	line-height: 5.4rem;
	@media (min-width: 0px) and (max-width: 999px) {
		font-size: 2.97rem;
		line-height: 3.56rem;
		margin-bottom: 3.25rem;
	}
}

p{
	margin-bottom: 2.916rem;
}
a{
	width: 18.16rem;
	@media (min-width: 0px) and (max-width: 999px) {
width: 31.87rem ;
font-size: 1.48rem;
svg{
	height: 1.4rem;
}
	}
}
`
export const WhyUsLine = styled.div`
	width: 1px;
	height: 25rem;
	background-color: #D9DBDF;
	@media (min-width: 0px) and (max-width: 999px) {
		height: 1px;
		width: 100%;
		margin: 2.97rem 0;
	}
`
export const WhyUsNumbers = styled.div`
padding-left: 7.95rem;
width: 53.3rem;
	@media (min-width: 0px) and (max-width: 999px) {
		width: 100%;
		padding-left: 0;
	}
ul{
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	row-gap: 3.6rem;
	column-gap: 2rem;

	li{
		width: 21.6rem;		
	@media (min-width: 0px) and (max-width: 999px) {
		width: 14.19rem;
	}
		.number{
			font-size: 4.5rem;
			font-weight: 400;
			line-height: 6.3rem;
		letter-spacing: -0.04rem;
		color: #161718;
	@media (min-width: 0px) and (max-width: 999px) {
		font-size: 2.97rem;
		line-height: 4.13rem;
	}

		}
		.description{
			font-size: 1.5rem;
			font-weight: 400;
			line-height: 2rem;
			color: #676B72;
		}
	}
}
`