import React, { Fragment, useContext, useState } from "react";
import { 
  NavigationMobileWrapper, 
  NavigationMobileContainer, 
  BurgerMenu
} from "./navigationMobile.styles";
import {NavLink, Outlet, useLocation } from "react-router-dom";
import {ReactComponent as MenuLines} from '../../assets/svgs/menu.svg' 
import Button from "../../components/button/button.component";
import { HashLink as Link } from "react-router-hash-link";
import {ReactComponent as RightArrow} from '../../assets/svgs/rarrow.svg' 
import {ReactComponent as CloseSvg} from '../../assets/svgs/close.svg' 
import { ModalContext } from "../../contexts/modal.context";
import StartProject from "../../components/startProject/startProject.component";

const NavigationMobile = () => {
const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };
  const closeNavbar= ()=>{
	setIsOpen(false)
  }
const smoothTop= ()=>{
	 window.scrollTo({ top: 0, behavior: 'smooth' });
	 toggleNavbar()
  }

const renderHeaderContent = () => {
	  const { pathname, hash } = location;

    if (pathname === '/') {
      if (hash === '#projectsId') {
        return "Projects";
      } else {
        return "Home";
      }
    }

    switch (location.pathname) {
      case '/AboutUs':
        return "About Us";
      case '/Reviews':
        return "Reviews";
      case '/ContactUs':
        return "Contacts";
      default:
        return "Home";
    }
	}

const {isModalOpen, setIsModalOpen} = useContext(ModalContext);
const specialFunction = ()=>{
	 setIsModalOpen(!isModalOpen) 
	 closeNavbar()
}
  return (
    <Fragment>
	 <div className="startProject"
		style={{ display: isModalOpen ? "block" : "none" }}
		>
			<StartProject/>
		</div>
      <NavigationMobileWrapper isOpen={isOpen}>

        <NavigationMobileContainer isOpen={isOpen}>
		  <div className="mainMenu">
          <BurgerMenu onClick={toggleNavbar} >
				{!isOpen ? <MenuLines/>  : <CloseSvg/>}
				
				{!isOpen ? renderHeaderContent() : "Close"}
          </BurgerMenu>
         <div onClick={specialFunction} className="buttonLink" >
				<Button id='startAProject' text="Start project"  />
			</div>
		  </div>

          {isOpen && (
            <div className="links">
							<div 
							style={{ background: location.pathname === '/' && location.hash !== '#projectsId' ? "#161718" : "none" }}
							className="link">
								<NavLink to="/" onClick={smoothTop}>
								<RightArrow
								style={{ display: location.pathname === '/' && location.hash !== '#projectsId' ? "block" : "none" }}
								/>
								Home</NavLink>
							</div>
							<div className="link"
							style={{ background: location.pathname === '/' && location.hash === '#projectsId' ? "#161718" : "none" }}
							>
								<Link 
								smooth to="/#projectsId" onClick={toggleNavbar}>
								<RightArrow
								style={{ display: location.pathname === '/' && location.hash === '#projectsId' ? "block" : "none" }}
								
								/> Projects</Link>
							</div>

							<div className="link"
							style={{ background: location.pathname === '/AboutUs' ? "#161718" : "none" }}
							>
								<NavLink to="/AboutUs"  onClick={toggleNavbar}><RightArrow
								style={{ display: location.pathname === '/AboutUs' ? "block" : "none" }}
								/> About us</NavLink>
							</div>

							<div className="link"
							style={{ background: location.pathname === '/Reviews' ? "#161718" : "none" }}
							>
								<NavLink to="/Reviews" onClick={toggleNavbar}><RightArrow
								style={{ display: location.pathname === '/Reviews' ? "block" : "none" }}
								
								/> Reviews</NavLink>
							</div>

							<div className="link"
							style={{ background: location.pathname === '/ContactUs' ? "#161718" : "none" }}
							>
								<NavLink to="/ContactUs" onClick={toggleNavbar}><RightArrow
								style={{ display: location.pathname === '/ContactUs' ? "block" : "none" }}
								/> Contacts</NavLink>
							</div>
						</div>
          )}
        </NavigationMobileContainer>
		  
      </NavigationMobileWrapper>
      <Outlet />
    </Fragment>
  );
};

export default NavigationMobile;
