import styled, { keyframes } from "styled-components";

const Fade = keyframes`
	  0% {
         opacity: 0;
		  	transform: scale(0.85);
    }
	 12.5% {
		 
	}
	25% {
		
		opacity: 1;
			  transform: scale(1);
		 
	}
	32.5% {
			
    }
	 34% {
		opacity: 0;
		
    }
	 62.5% {
        
    }
	 75% {
        
    }
	 87.5% {
       
    }
    100% {
       
    }
`;

export const AnimatedPoligonContainer = styled.div`
	position: relative;
@media (min-width: 0px) and (max-width: 999px) {
	scale: 0.8;
}
	.skelet{
		/* position: relative; */
		svg{
			width: 39.3rem;
		}
	}
	.polAb{
		top: 0;
		left: 0;
		position: absolute;
		opacity: 0;
		animation: ${Fade} 5s infinite;
		transform-origin: center;
		

	}

	.pol1{
		top: 22.7%;
		left: 25.75%;
		animation-delay: 0s;
		transform-origin: center;
		/* display: none; */
		svg{
			width: 18.6rem ;
		}


	}
	.pol2{
		top: 27.6%;
		left: 23.3%;
		/* display: none; */
		svg{
			width: 18.083rem ;
		}
		 animation-delay: 1s;
	}
	.pol3{
		top: 11.7%;
		left: 32.7%;
		svg{
			width: 12.3rem ;
		}
		/* display: none; */
		animation-delay: 2s;
		
	}
	.pol4{
		top: 26%;
		left: 30%;
		svg{
			width:14.83rem ;
		}
		/* display: none; */
		animation-delay: 3s;
	}
	.pol5{
		top: 27.5%;
		left: 28.8%;
		svg{
			width:15.75rem ;
		}
		/* display: none; */
		animation-delay: 4s;
		
	}
`