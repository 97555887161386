import styled from "styled-components";

export const ProcessWrapper = styled.div`
width: 100%;
background-color: #F3F3F3;
`
export const ProcessContainer = styled.div`
width: 112rem;
margin: 0 auto;
padding: 8.3rem 0;
display: flex;
justify-content: space-between;
@media (min-width: 0px) and (max-width: 999px) {
width: 31.87rem ;
flex-direction: column-reverse;
padding: 4.46rem 0 ;

}

.processDescription{
	width: 53.3rem;
@media (min-width: 0px) and (max-width: 999px) {
	width: 100%;
}
	h1{
		font-size: 4.5rem;
		line-height: 5.4rem;
		margin-bottom: 3.5rem;
		@media (min-width: 0px) and (max-width: 999px) {
		font-size: 2.97rem;
		line-height: 3.56rem;
		margin-bottom: 3.9rem;
		}
	}
	ul{
		li{
			margin-bottom: 2.6rem;
			h4{
				margin-top:0rem;
			}
			p{
		@media (min-width: 0px) and (max-width: 999px) {
			font-size: 1.38rem;
		}
			}
			display: flex;
			gap: 2rem;
			align-items: start;
			.icon{
				padding: 1.16rem;
				background-color: #FFFFFF;
				display:flex ;
				justify-content: center;
				align-items: center;
				svg{
					width: 2.16rem;
				}
			}
		}
		.lastProcess{
			margin-top: 5.3rem;
		}
	}
}


.processImgMobile{
display: none;
@media (min-width: 0px) and (max-width: 999px) {
	margin-bottom: 3.9rem;
	display: block;
	width: 100%;
	height: 22.3rem;
	img{
		height: 100%;
		width: 100%;
	}
}
}

.processImgFull{
@media (min-width: 0px) and (max-width: 999px) {
	display: none;
}
	width: 52rem;
	img{
		width: 100%;
	}
}

`