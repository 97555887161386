import { ReactComponent as RightArrow } from "../../assets/svgs/rarrow.svg";
import { Characteristics, CharacteristicsSlide } from "./carouselLine.styles";

const items = [
  "UX Design",
  "Development",
  "Website",
  "Agency",
  "Brand Guideline",
  "Startup",
  "Mobile applications",
  "Maintenance",
];

const CarouselLine = () => {
  return (
    <Characteristics>
      <CharacteristicsSlide>
        {items.concat(items).map((item, index) => (
          <li key={index}>
            <RightArrow />
            <span>{item}</span>
          </li>
        ))}
      </CharacteristicsSlide>
    </Characteristics>
  );
};

export default CarouselLine;
