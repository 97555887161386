import { HomeSectionOneContainer, HomeSectionOneWrapper } from "./home.styles";
import secOneImg from "../../assets/img/hs1.jpg"
import {ReactComponent as RightArrow} from "../../assets/svgs/rarrow.svg"
import CarouselLine from "../../components/carouselLine/carouselLine.component";
import Projects from "../../components/projects/projects.component";
import Services from "../../components/services/services.component";
import OurClients from "../../components/clients/clients.component";
const Home = ()=>{
	return(
		<>
			<HomeSectionOneWrapper>
				<HomeSectionOneContainer>
					<div className="secOneDescription">
						<h1>Full Stack Design Studio</h1>
						<p>A software design team specialized in branding, websites, products and systems from 0 <RightArrow/> 1</p>
					</div>
					<div className="secOneImg">
						<img src={secOneImg} alt="secOneImg" />
					</div>
				</HomeSectionOneContainer>
			</HomeSectionOneWrapper>
			<CarouselLine/>
			<Projects/>
			<Services/>
			<OurClients/>
		</>
	)
}

export default Home;