import { ReviewsContainer, ReviewsWrapper } from "./reviews.styles";
import rev1 from '../../assets/reviews/rev1.jpg'
import rev2 from '../../assets/reviews/rev2.jpg'
import rev3 from '../../assets/reviews/rev3.jpg'
import rev4 from '../../assets/reviews/rev4.jpg'
import Carousel from "../../components/carousel/carousel.component";

const testimonials = [
  {
    id: 1,
    name: 'Albert Flores',
    image: rev1,
    review: "They were polite, professional, and extremely skilled at all aspects of project design and execution. The app has numerous downloads and positive ratings in both app stores. The team’s efficiency and diligence made the project a success. EBS took responsibility for bugs and resolved them post-launch at no cost.",
    rating: 4,
  },
   {
    id: 2,
    name: 'Savannah Nguyen',
    image: rev2,
    review: "I’m the founder and CEO of a Swedish educational company providing apps for various targeted skills, including those for driver’s licences and finances. We had been providing our educational content in an offline, hard copy format, but we wanted to build apps that contained the same information.",
    rating: 4,
  },
   {
    id: 3,
    name: 'Darlene Robertson',
    image: rev3,
    review: "ITBRICKS built two apps in Android and iOS. The apps allow users to utilize social networks as well as phones and allows users to connect to each other, as well as integrate several APIs. Because we were so happy with how they worked, we didn't reach out to anyone else for our second project.",
    rating: 4,
  },
   {
    id: 4,
    name: 'Kristin Watson',
    image: rev4,
    review: 'We worked with EBS Integrator to make a new program in three different platforms – software, mobile application, and invoicing functionalities. EBS worked from planning to implementation of the solution. We wanted to be able to see employee tasks from different views. A customer, an employee, and I would each need to see the tasks.',
    rating: 4,
  }
];

 

const ReviewsPage = ()=>{
	 
	return(
		<>
			<ReviewsWrapper>
				<ReviewsContainer>
					<h1>Reviews</h1>
					<Carousel testimonials={testimonials} />
				</ReviewsContainer>
			</ReviewsWrapper>
		</>
	)
}

export default ReviewsPage;