import styled from "styled-components";

export const NavigationWrapper = styled.div`
position: fixed;
bottom: 1.5%;
left: 50%;
transform: translateX(-50%);
background: #000000B2;
border-radius: 1rem;
z-index: 100;
@media (min-width: 0px) and (max-width: 999px) {
	display: none;
}
`

export const NavigationContainer = styled.div`
width: 75rem;
padding: 1rem;
`
export const FullNavbar = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	.home{
		border-radius: 1rem;
		background-color: #676B72;
		a{
			opacity: 1 !important;
		}
		svg{
			width: 2rem;
			path{
				fill: white;
			}
		}

	a{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 4.16rem;
		height: 4.16rem;
		opacity: 0.6;
	}
	
}

.links{
	display: flex;
	.link{
		margin-right: 0.83rem;
		&:hover{
			a{
				opacity: 1 !important;
			}
		}
	}
	a{
		font-size: 1.5rem;
		font-weight: 600;
		line-height: 2rem;
		padding: 1rem 1.3rem;
		color: white;
		opacity: 0.6;
	}
	a.active{
			opacity: 1;
	}
}


.buttonLink{
	
	a{
		border-radius: 1rem;
		width: 14.16rem;
		color: #121212;
		background-color: #FFFFFF;
		font-size: 1.3rem;
		font-weight: 600;
		line-height: 1.83rem;
		text-transform: none;
		
		svg{
			path{
				fill: #121212;
			}
		}
	}
}
`

