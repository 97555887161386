import { ProcessContainer, ProcessWrapper } from "./process.styles";

import {ReactComponent as ResearchSvg} from "../../assets/svgs/research.svg"
import {ReactComponent as ConceptsSvg} from "../../assets/svgs/concepts.svg"
import {ReactComponent as DevelopmentSvg} from "../../assets/svgs/development.svg"

import processImg from '../../assets/img/process.jpg'
const Process =()=>{
	return(
		<ProcessWrapper>
			<ProcessContainer>
				<div className="processDescription">
					<h1>Our process</h1>
					<div className="processImgMobile">
						<img src={processImg} alt="processImg" />
					</div>
					<ul>
						<li>
							<div className="icon"><ResearchSvg/></div>
							<div className="description">
								<h4>Research</h4>
								<p>In the discovery phase, we extensively research your project, employing various methods to understand your audience and competitors. This informs our design and development decisions, aligning with your business goals.</p>
							</div>
						</li>
						<li>
							<div className="icon"><ConceptsSvg/></div>
							<div className="description">
								<h4>Concepts</h4>
								<p>Understanding your needs, we embark on a creative journey to develop design ideas. Through collaborative brainstorming and prototyping, we bring concepts to life and refine them with user feedback.</p>
							</div>
						</li>
						<li className="lastProcess">
							<div className="icon"><DevelopmentSvg/></div>
							<div className="description">
								<h4>Development</h4>
								<p>Our skilled developers transform designs into functional versions on staging servers. Close collaboration ensures seamless integration of designs into development.</p>
							</div>
						</li>
					</ul>
				</div>

				<div className="processImgFull">
					<img src={processImg} alt="processImg" />
				</div>
			</ProcessContainer>
		</ProcessWrapper>
	)
}

export default Process;