import { ContactUsContainer, ContactUsWrapper } from "./contactUs.styles";
import location from '../../assets/img/location.jpg'

import {ReactComponent as Phone} from '../../assets/svgs/phoneCall.svg'
import {ReactComponent as Sms} from '../../assets/svgs/email.svg'
import {ReactComponent as Location} from '../../assets/svgs/location.svg'
const ContactUsPage = ()=>{
	return(
		<>
				<ContactUsWrapper>
					<ContactUsContainer>
						<div className="contactUs">
							<h1>Contact Us</h1>
							<ul>
								<li><a href="tel:+37369025925"><span className="icon"><Phone/></span><span className="reference">+373 69 025 925</span></a></li>
								<li><a href="mailto:info@itbrics.com"><span className="icon"><Sms/></span><span className="reference">info@itbrics.com</span></a></li>
								<li><a href="https://maps.app.goo.gl/YncSdVCu8nkvzVqL7"><span className="icon"><Location/></span><span className="reference">
									Miron Costin 17/2 str. of.519, Chisinau, MD-2068, Republic of Moldova</span></a></li>
							</ul>
						</div>

						<div className="locationImg">
							<img src={location} alt="location" />
						</div>
					</ContactUsContainer>
				</ContactUsWrapper>

		</>
	)
}

export default ContactUsPage;