import { Fragment, useState, useRef } from "react"
import { Outlet } from "react-router-dom"
import { GiftModalContainer, GiftModalWrapper } from "./giftModal.styles";
import giftImg from '../../assets/img/gift.jpg'
import emailjs from '@emailjs/browser';
const GiftModal = ()=>{
  const form = useRef();
	 const [active, setActive] = useState(true);

  const sendEmail = (e) => {
    e.preventDefault();
setActive(false)
    emailjs
      .sendForm('service_o2h3iso', 'template_xrkgjba', form.current, {
        publicKey: 'eb4b8D85LOb28CLsH',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };





  const toggleActive = () => {
    setActive(false);
  }
	return(
		<Fragment>
			<Outlet/>
			<GiftModalWrapper
			
			style={{ display: active ? "block" : "none" }}>
			<div className="closeBackground" onClick={toggleActive}></div>
				<GiftModalContainer>
					<div className="giftDescriptionContainer">
						<h3>SAVE UP TO</h3>
						<h2 className="promotionNumber">
							30%
						</h2>
						<h3>OFF IN YOUR FIRST PROJECT</h3>

						<form ref={form} onSubmit={sendEmail}>
							<input type="text" required placeholder="enter your mail here" name="form_mail" />
							<div className="buttonContainer">
								<button className="closeModal" onClick={toggleActive}>NO, THANKS</button>
								<button type="submit" className="submitButton">GET IT</button>
							</div>
						</form>
					</div>
					<div className="giftImg">
						<img src={giftImg} alt="giftImg" />
					</div>
				</GiftModalContainer>
			</GiftModalWrapper>
		</Fragment>
	)
}

export default GiftModal;