import styled from "styled-components";

export const StartProjectWrapper = styled.div`
position: fixed;
width: 100vw;
height: 100vh;
top: 0;
left: 0;
background-color: #00000080;
z-index: 1000;
.closeBackground{
		width: 100%;
		height: 100%;
		z-index: 0;
	}
`

export const StartProjectContainer = styled.div`
	background-color: #ffffff;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 56.6rem;
@media (min-width: 0px) and (max-width: 999px) {
	gap: 0;
	flex-direction: column-reverse;
	transform: none;
	top: auto;
	bottom: 0;
	left: 0;
	width: 100%;
}
@media (min-width: 515px) and (max-width: 649px) {
		scale: 0.8;
	}
	@media (min-width: 650px) and (max-width: 999px) {
		scale: 0.6;
	}
	.descriptionProject{
		width: 100%;
		background-color: #ECEDEF;
		padding: 2.6rem 1.6rem;
		position: relative;
		.close{
			cursor: pointer;
			width: 3rem;
			height: 3rem;
			top: 2rem;
			right: 1.4rem;
			background-color: none;
			font-size: 2rem;
			position: absolute;
			color: #676B72;
			opacity: 0.6;
		}
		h3{
			font-size: 1.6rem;
			font-weight: 600;
			line-height: 2.3rem;
			color:#161718 ;
			margin-bottom: 0.6rem;
		}
		p{
			font-size: 1.5rem;
			font-weight: 400;
			line-height: 2rem;
			color: #676B72;
			span{
				font-size: 1.5rem;
				font-weight: 700;
				line-height: 2rem;
				color: #1E966E;
			}
		}
	}

	.formContainer{
		padding: 1.6rem;
		width: 100%;
		form{
			width: 100%;
			label{
				font-size: 1rem;
				font-weight: 600;
				line-height: 1.3rem;
				color: #676B72;
				margin-bottom: 0.3rem;
			}
			input, textarea{
				width: 100%;
				background-color: #F5F6F7;
				font-size: 1.3rem;
				font-weight: 400;
				line-height: 1.83rem;
				color: #848891;
				padding: 1rem;
				border: none;
				outline: none;
			}
			.contacts{
				margin-top: 2rem;
				width: 100%;
				display: flex;
				gap: 0.6rem;
@media (min-width: 0px) and (max-width: 999px) {
	flex-direction: column;
	row-gap: 2rem;
}
				.contact{
					width: 100%;
					.inputPhone{
						position: relative;
						input{
							padding-left: 4.83rem;
							color: #161718;
							

						}
						.trei{
							position: absolute;
							top: 50%;
							left: 1rem;
							transform: translate(0%, -50%);
							font-size: 1.3rem;
							font-weight: 700;
							line-height: 1.83rem;
							color: #161718;

						}
					}
				}
			}
			.projectType{
				textarea{
					resize: none;
					width: 100%;
					height: 8rem;
				}
				margin-bottom: 2rem;
			}
			.submitButton{
				display: flex;
				justify-content: end;
				width: 100%;
				button{
					cursor: pointer;
					width: 10rem;
					height: 3.83rem;
					font-size: 1.3rem;
					font-weight: 600;
					line-height: 1.83rem;
					text-align: center;
					color: #FFFFFF;
					background-color: #121212;
				@media (min-width: 0px) and (max-width: 999px) {
					width: 100%;
				}
				}
			}
		}
	}
`

export const Line = styled.div`
width: 100%;
height: 1px;
background-color: #ECEDEF;
margin: 2rem 0;
`

