import styled from "styled-components";

export const ProjectsWrapper = styled.div`
display: block;
width: 100%;
`

export const ProjectsContainer = styled.div`
width: 112rem;
margin: 0 auto;
	@media (min-width: 0px) and (max-width: 999px) {
		padding-top: 4.46rem;
		width: 31.87rem ;
		padding-bottom: 4.5rem;
	}
`

export const ProjectsHeaderContainer = styled.div`
padding: 10rem 0 6.6rem;
display: flex;
align-items: center;
justify-content: space-between;
	@media (min-width: 0px) and (max-width: 999px) {
		align-items: start;
		flex-direction: column;
		padding: 0 0 0 0;
	}
`
export const ProjectsHeader = styled.h1`
width: 27%;
font-size: 4.5rem;
font-weight: 600;
line-height: 5.4rem;
text-align: left;
color: #161718;
	@media (min-width: 0px) and (max-width: 999px) {
		width: 64%;
		font-size: 2.97rem;
		line-height: 3.56rem;
		margin-bottom: 0.92rem;
	}

`

export const ProjectsHeaderToReview = styled.div`

width: 30%;
	@media (min-width: 0px) and (max-width: 999px) {
		width: 100%;
	}
p{
font-size: 1.5rem;
font-weight: 400;
line-height: 2rem;
text-align: left;
color: #161718;
padding-bottom: 2rem;
}
margin-bottom: 4.46rem;


`
export const Line = styled.div`
	width: 100%;
	height:1px;
	background-color: #ECEDEF;
	margin: 4rem 0;
	@media (min-width: 0px) and (max-width: 999px) {
	margin: 2.97rem 0;
	}
`