import styled from "styled-components";

export const OurClientsWrapper = styled.div`
width: 100%;
background-color: #F3F3F3;

`

export const OurClientsContainer = styled.div`
width: 120rem;
margin: 0 auto;
padding-top: 10rem;
@media (min-width: 0px) and (max-width: 999px) {
	width: 34.8513rem ;
	padding-top: 4.46rem;
}
.presentation{
	width: 100%;
	text-align: center;
	padding-bottom: 4.6rem;
@media (min-width: 0px) and (max-width: 999px) {
		width: 31.87rem ;
		margin: 0 auto;
}
	h1{
		font-size: 4.5rem;
		font-weight: 600;
		line-height: 5.4rem;
		color: #161718;
@media (min-width: 0px) and (max-width: 999px) {
	font-size: 2.97rem;
	line-height: 3.56rem;
}
	}
	p{
		font-size: 1.5rem;
		font-weight: 400;
		line-height: 2rem;
		color: #161718;
		width: 52%;
		margin: 1.6rem auto 0 auto;
@media (min-width: 0px) and (max-width: 999px) {
	width: 100%;
}
	}
}
.map{
	width: 100%;
@media (min-width: 0px) and (max-width: 999px) {
	width: 34.8513rem  ;
	height: 11.9rem;
}
	img{
		width: 100%;
		height: 100%;
@media (min-width: 0px) and (max-width: 999px) {
	object-fit: cover;
}
	}
}
`