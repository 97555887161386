import { Route, Routes } from "react-router-dom";
import Home from "./routes/home/home.component";
import AboutUsPage from "./routes/aboutUs/aboutUsPage.component";
import ReviewsPage from "./routes/reviews/reviews.component";
import ContactUsPage from "./routes/contactUs/contactUsPage.component";
import Navigation from "./routes/navigation/navigation.component";
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Header from "./components/header/header.component";
import Footer from "./components/footer/footer.component";
import NavigationMobile from "./routes/navigationMobile/navigationMobile.component";
import GiftModal from "./routes/giftModal/giftModal.component";

function App() {
	const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
   <Routes>
		<Route path="/" element={<Header/>}>
			<Route path="/" element={<Navigation/>}>
				<Route path="/" element={<NavigationMobile/>}>
					<Route path="/" element={<Footer/>}>
						<Route path="/" element={<GiftModal/>}>
							<Route index element={<Home/>}/>
							<Route path="/AboutUs" element={<AboutUsPage/>}/>
							<Route path="/Reviews" element={<ReviewsPage/>}/>
							<Route path="/ContactUs" element={<ContactUsPage/>}/>
						</Route>
					</Route>
				</Route>
			</Route>
		</Route>
	</Routes>
  );
}

export default App;
