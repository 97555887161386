import { Fragment, useContext} from "react";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import { FullNavbar, NavigationContainer, NavigationWrapper } from "./navigation.styles";
import {ReactComponent as Home} from '../../assets/svgs/home.svg'
import Button from "../../components/button/button.component";
import { HashLink as Link } from "react-router-hash-link";
import StartProject from "../../components/startProject/startProject.component";
import { ModalContext } from "../../contexts/modal.context";

const Navigation =()=>{
	const location = useLocation();
	const smoothTop= ()=>{
	 window.scrollTo({ top: 0, behavior: 'smooth' });
  }
const {isModalOpen, setIsModalOpen} = useContext(ModalContext);

	return(
		<Fragment>
		<div className="startProject"
		style={{ display: isModalOpen ? "block" : "none" }}
		>
			<StartProject/>
		</div>
			<NavigationWrapper >
				<NavigationContainer>
					<FullNavbar>
						<div className="home">
							<NavLink to="/"  onClick={smoothTop}><Home/></NavLink>
						</div>

						<div className="links">
							<div className="link">
								<Link 
								style={{ opacity: location.pathname === '/' && location.hash === '#projectsId' ? 1 : 0.6 }}
								smooth to="/#projectsId">Projects</Link>
							</div>

							<div className="link">
								<NavLink to="/AboutUs">About us</NavLink>
							</div>

							<div className="link">
								<NavLink to="/Reviews">Reviews</NavLink>
							</div>

							<div className="link">
								<NavLink to="/ContactUs">Contacts</NavLink>
							</div>
						</div>

						<div className="buttonLink" onClick={()=> setIsModalOpen(!isModalOpen)} >
							<Button id='startAProject' text="Start project"   />
						</div>
					</FullNavbar>
				</NavigationContainer>
			</NavigationWrapper>
			<Outlet/>
		</Fragment>
	)
}


export default Navigation;