import styled from "styled-components";

export const HomeSectionOneWrapper = styled.div`
	width: 100%;
	height: 58.3rem;
	@media (min-width: 0px) and (max-width: 999px) {
		height: auto ;
	}
`


export const HomeSectionOneContainer = styled.div`
	height: 100%;
	width: 120rem;
	margin: 0 auto;
	display: flex;

	@media (min-width: 0px) and (max-width: 999px) {
		width: 34.8513rem ;
		flex-direction: column;
	}

	.secOneDescription{
		padding: 6.6rem 0 6.6rem 4rem;
		width: 50%;

		@media (min-width: 0px) and (max-width: 999px) {
			width: 100%;
			padding: 2.973rem 0 2.973rem 1.486rem;
		}

		h1{
			width: 80%;
			font-size: 6.6rem;
			color: #161718;
			font-weight: 600;
			line-height: 8rem;
			@media (min-width: 0px) and (max-width: 999px) {
				width: 80%;
				font-size: 3.9rem;
				line-height: 4.68rem;
			}
		}
		p{
			margin-top: 20.3rem;
			width: 72%;
			font-size: 2rem;
			font-weight: 400;
			line-height: 2.83rem;
			color: #161718;
			@media (min-width: 0px) and (max-width: 999px) {
				margin-top: 1.486rem;
				width: 95%;
				line-height: 2.04rem;
				font-size: 1.486rem;
			}
		}

		svg{
			height: 1.4rem;
			path{
				fill:#161718 ;
			}
			@media (min-width: 0px) and (max-width: 999px) {
			height: 1rem;
			}
		}
	}
	.secOneImg{
		height: 100%;
		img{
			height: 100%;
		}
		@media (min-width: 0px) and (max-width: 999px) {
			height: 46.4684rem;
			width: 100%;
			img{
				height: 100%;
				width: 100%;
				object-fit: cover;
			}
		}

	}
`