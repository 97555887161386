import AnimatedPoligon from "../../components/animatedPoligon/animatedPoligon.component";

import { AboutUsContainer, AboutUsFirstSection, AboutUsWrapper, BlackContainer, ImgContainer, ImplicationsContainer } from "./aboutUs.styles";

import implImg from '../../assets/img/impl.jpg'
import mobilelImg from '../../assets/img/teamMobile.jpg'
import Process from "../../components/process/process.component";
import WhyUs from "../../components/whyUs/whyUs.component";

import TeamImg from '../../assets/img/team.jpg';
const AboutUsPage = ()=>{
	return(
		<AboutUsWrapper>
			<AboutUsContainer>
			<AboutUsFirstSection>
				<div className="aboutUsDescription">
					<h1>About Us</h1>
					<p>Take a moment to familiarize yourself with our mindset. These are the guiding principles and insights about us that provide a deeper understanding of our identity.</p>
				</div>

				<AnimatedPoligon/>
			</AboutUsFirstSection>

			<ImplicationsContainer>
				<div className="ImplImg">
					<img src={implImg} alt="img" />
				</div>


				<div className="implication">
					<h4>We help our clients generate revenue</h4>
					<p>In our design approach, we prioritize the primary business needs and goals. Recognizing that profitability is fundamental to every project, we aim to deliver solutions that contribute to our clients' financial success.</p>

					<h4>We create exclusively user-oriented design</h4>
					<p>We continually ask: What is the most effective way to help the user achieve their goal? What constitutes the most user-friendly flow? Our focus is always on meeting the needs and expectations of the users.</p>

					<h4>We establish a "bridge" with developers</h4>
					<p>We create designs that are not only visually compelling but also technically feasible. Each design comes with detailed specifications and necessary assets, fostering a smooth and efficient partnership with developers.</p>
				</div>

			</ImplicationsContainer>
			</AboutUsContainer>
			<Process />
			<WhyUs/>
			
			<ImgContainer>
			<img src={TeamImg} alt="img" className="fullImg"  />
			<img src={mobilelImg} alt="img" className="mobileImg"  />
			</ImgContainer>
				<BlackContainer></BlackContainer>
			</AboutUsWrapper>

	)
}

export default AboutUsPage;