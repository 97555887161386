import {  useContext, useRef } from "react"
import emailjs from '@emailjs/browser';

import { Line, StartProjectContainer, StartProjectWrapper } from "./startProject.styles";
import { ModalContext } from "../../contexts/modal.context";


const StartProject =()=>{
  const form = useRef();
const {isModalOpen, setIsModalOpen} = useContext(ModalContext);

  const sendEmail = (e) => {
    e.preventDefault();
		setIsModalOpen(!isModalOpen)

    emailjs
      .sendForm('service_g0g99m4', 'template_6cs047a', form.current, {
        publicKey: 'eb4b8D85LOb28CLsH',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );

  };

  
	return(
		<StartProjectWrapper
		style={{ display: isModalOpen ? "block" : "none" }}>
		<div className="closeBackground" onClick={()=> setIsModalOpen(!isModalOpen)}></div>
			<StartProjectContainer>
				<div className="descriptionProject">
					<h3>Start project</h3>
					<p>Take advantage now and enjoy up to <span>30% off</span> on your first order! Our specialists will get in touch with you shortly.</p>
					<button className="close" onClick={()=> setIsModalOpen(!isModalOpen)}>x</button>
				</div>
				<div className="formContainer">
					<form ref={form} onSubmit={sendEmail}>
						<div className="name">
							<label htmlFor="name">Name/Surname</label>
							<input type="text" required id="name" name="user_name" placeholder="Wade Warren"/>
						</div>

						<div className="contacts">
							<div className="mail contact">
								<label htmlFor="mail">Mail</label>
								<input type="text" required id="mail" name="user_mail" placeholder="example.example@gmail.com" />
							</div>

							<div className="phone contact">
								<label htmlFor="phone">Phone number
								</label>							
								<div className="inputPhone">
									<input type="text" required id="phone" name="user_phone" placeholder="XX XXX XXX" />
									<div className="trei">
										+373
									</div>
								</div>
							</div>
						</div>

						<Line></Line>

						<div className="projectType">
							<label htmlFor="prtype">Project type</label>
							<textarea id="prtype" name="user_project" required placeholder="Add a short description of your project" ></textarea>
						</div>

						<div className="submitButton">
							<button type="submit">Send</button>
						</div>

					</form>
				</div>
			</StartProjectContainer>
		</StartProjectWrapper>
	)
}

export default StartProject;