import { ProjectContainer, ProjectDescription, ProjectImg } from "./project.styles";


const Project = (props)=>{
	return(
		<ProjectContainer>
			<ProjectImg>
				<img src={props.imgUrl} alt={props.imgUrl} />
			</ProjectImg>

			<ProjectDescription>
				<div className="description">
					<h2>{props.descHeader}</h2>
					<p>{props.desc}</p>
				</div>

				<div className="skills">
					<span className="skill1 label">{props.skill1}</span>
					<span className="skill2 label">{props.skill2}</span>
					<span className="skill3 label">{props.skill3}</span>
				</div>
			</ProjectDescription>
		</ProjectContainer>
	)
}

export default Project;