import styled from "styled-components";

export const CarouselContainer = styled.div`
	width: 102rem;
  margin: 0 auto;
  position: relative;
  @media (min-width: 0px) and (max-width: 999px) {
		width: 31.87rem ;
	}

  svg{
	width: 1.6rem;
	height: 1.6rem;
	
  }
  .testimonial {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .testimonial-content {
	width: 66.6rem;
    text-align: center;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }

  .testimonial-content.fade {
    opacity: 0;
  }

  .testimonial-image {
    width: 16.6rem;
    height: 16.6rem;
    object-fit: cover;
    margin-bottom: 3.5rem;
  }

  .testimonial-review {
		font-size: 1.6rem;
		font-weight: 400;
		line-height: 2.3rem;
		color: #676B72;
		margin-bottom: 2rem;
	@media (min-width: 0px) and (max-width: 999px) {
		font-size: 1.48rem;
		height: 16.35rem;
	}
  }

  .testimonial-name {
		font-size: 2rem;
		font-weight: 600;
		line-height: 2.83rem;
		color: #161718;
  }

  .testimonial-rating {
    font-size: 2.1rem;
    color: gold;
	
  }

  .arrow {
    background-color: #121212;
	 width: 3.6rem;
	 height: 3.6rem;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    position: absolute;
    top: 15%;
    z-index: 1;
  }

  .left-arrow {
    left: 0;
  }

  .right-arrow {
    right: 0;
  }

  .dots {
    text-align: center;
    margin-top: 2rem;
  }

  .dot {
    height: 1.3rem;
    width: 1.3rem;
    margin: 0 0.65rem;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
  }

  .dot.active {
    background-color: #717171;
  }
`;
